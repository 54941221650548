<template>
    <validation-observer v-slot="{ invalid }">
        <div class="field">
            <label class="checkbox">
                <input type="checkbox" v-model="ic.mfaEnabled" />
                MFA Enabled
            </label>
        </div>
        <div class="field">
            <label class="label">MFA Media</label>
            <div style="display: flex; flex-direction: row; column-gap: 16px;">
                <label class="checkbox">
                    <input type="checkbox" v-model="ic.mfaSms" />
                    SMS
                </label>
                <label class="checkbox">
                    <input type="checkbox" v-model="ic.mfaEmail" />
                    Email
                </label>
            </div>
        </div>
        <validation-provider
            tag="div"
            class="field"
            v-slot="{ errors, failed }"
            rules="required|max:255"
        >
            <div class="control">
                <label class="label">OTP Expiration (seconds)</label>
                <input name="OTP Expiration" type="number" class="input" v-model="ic.mfaExpiration"/>
            </div>
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
        </validation-provider>

        <div class="panel">
            <h2 class="panel-heading">SMS</h2>
            <div class="panel-block">
                <validation-provider
                    tag="div"
                    v-slot="{ errors, failed }"
                    rules="max:255"
                    class="control"
                >
                    <label class="label">SMS Content</label>
                    <textarea
                        class="textarea"
                        v-model="ic.mfaSmsContent"
                        name="SMS Content"></textarea>
                    <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
            </div>
            <div role="alert" class="has-text-danger" style="padding-left: 1rem;">{{ smsError }}</div>
            <div class="panel-block">
                <div class="dict-wrapper">
                    <div class="dict-title">Click to copy dictionary</div>
                    <div class="dict-body">
                        <div v-for="inst in lstDictionary" :key="inst" class="dict-item" @click="copyDict(inst)">{{
                                inst
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel">
            <h2 class="panel-heading">Email</h2>
            <div class="panel-block">
                <validation-provider
                    tag="div"
                    v-slot="{ errors, failed }"
                    rules="max:255"
                    class="control"
                >
                    <label class="label">Email From</label>
                    <input
                        class="input"
                        v-model="ic.mfaEmailFrom"
                        name="Email From"
                    />
                    <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
            </div>
            <div class="panel-block">
                <validation-provider
                    tag="div"
                    v-slot="{ errors, failed }"
                    rules="max:255"
                    class="control"
                >
                    <label class="label">Email Subject</label>
                    <input
                        class="input"
                        v-model="ic.mfaEmailSubject"
                        name="Email Subject"
                    />
                    <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
            </div>
            <div class="panel-block">
                <validation-provider
                    tag="div"
                    rules="max:1000"
                    class="control"
                    v-slot="{ errors, failed }"
                >
                    <label class="label">Email Content</label>
                    <editor name="Email Content" :api-key="tinyMceApi" v-model="ic.mfaEmailContent" :init="{
                plugins: 'lists link image table code help wordcount'
              }"/>
                    <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
                </validation-provider>
            </div>
            <div role="alert" class="has-text-danger" style="padding-left: 1rem;">{{ emailError }}</div>
            <div class="panel-block">
                <div class="dict-wrapper">
                    <div class="dict-title">Click to copy dictionary</div>
                    <div class="dict-body">
                        <div v-for="inst in lstDictionary" :key="inst" class="dict-item" @click="copyDict(inst)">
                            {{ inst }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="field">
            <p class="content"><em>* indicates required field</em></p>
        </div>
        <div class="field">
            <div class="buttons">
                <button class="button is-success" :disabled="invalid" @click="save">
                    Save
                </button>
                <button class="button is-light" @click="revert">
                    Revert
                </button>
            </div>
        </div>

    </validation-observer>
</template>
<script>
import {mapActions} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Editor from '@tinymce/tinymce-vue'

export default {
    components: {ValidationObserver, ValidationProvider, Editor},
    data() {
        return {
            ic: {
                mfaEnabled: null,
                mfaExpiration: null,
                mfaSms: null,
                mfaSmsContent: null,
                mfaEmail: null,
                mfaEmailFrom: null,
                mfaEmailSubject: null,
                mfaEmailContent: null,
            },
            lstDictionary: ['${otpCode}'],
            tinyMceApi: process.env.VUE_APP_TINYMCE_KEY,
            emailError: null,
            smsError: null,
        };
    },
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            vm.$store.commit("SHOW_PROGRESS");
            vm.$store.commit("HIDE_NOTIFICATION");
            try {
                await vm.fetchPaymentPortalGlobalConfig();
                await vm.fetchPaymentPortalInstanceConfig(to.params.id);
                await vm.fetchInstances();
                vm.loadView.call(vm, to.params.id);
            } catch (e) {
                vm.$store.commit(
                    "SHOW_NOTIFICATION",
                    `Error loading InstanceMain; ${e}`
                );
            } finally {
                vm.$store.commit("HIDE_PROGRESS");
            }
        });
    },
    async beforeRouteUpdate(to, from, next) {
        this.$store.commit("SHOW_PROGRESS");
        this.$store.commit("HIDE_NOTIFICATION");
        try {
            await this.fetchPaymentPortalInstanceConfig(to.params.id);
            this.loadView(to.params.id);
        } catch (e) {
            this.$store.commit(
                "SHOW_NOTIFICATION",
                `Error loading instance id=${to.params.id}; ${e}`
            );
        } finally {
            this.$store.commit("HIDE_PROGRESS");
            next();
        }
    },
    methods: {
        ...mapActions("inst", ["fetchInstances"]),
        ...mapActions([
            "fetchPaymentPortalGlobalConfig",
            "fetchPaymentPortalInstanceConfig",
            "saveInstanceConfig"
        ]),
        loadView(id) {
            this.ic.instanceId = id;
            this.ic.mfaEnabled = this.$store.state.instanceConfig.mfaEnabled;
            this.ic.mfaExpiration = this.$store.state.instanceConfig.mfaExpiration;
            this.ic.mfaSms = this.$store.state.instanceConfig.mfaSms;
            this.ic.mfaSmsContent = this.$store.state.instanceConfig.mfaSmsContent;
            this.ic.mfaEmail = this.$store.state.instanceConfig.mfaEmail;
            this.ic.mfaEmailFrom = this.$store.state.instanceConfig.mfaEmailFrom;
            this.ic.mfaEmailSubject = this.$store.state.instanceConfig.mfaEmailSubject;
            this.ic.mfaEmailContent = this.$store.state.instanceConfig.mfaEmailContent;
        },
        async save() {
            window.scrollTo(0, 0);
            let smsValidation = this.validateSmsContent(this.ic.mfaSmsContent)
            if (typeof smsValidation === 'string') {
                this.smsError = smsValidation;
                return;
            }
            let emailValidation = this.validateEmailContent(this.ic.mfaEmailContent);
            if (typeof emailValidation === 'string') {
                this.emailError = emailValidation;
                return;
            }

            this.smsError = null;
            this.emailError = null;
            await this.saveInstanceConfig(this.ic);
        },
        async revert() {
            await this.fetchPaymentPortalInstanceConfig(this.$route.params.id);
            this.loadView();
        },
        copyDict(myValue) {
            navigator.clipboard.writeText(myValue);
        },
        validateEmailContent(value) {

            if (!this.ic.mfaEnabled || !this.ic.mfaEmail) {
                return true;
            }
            if (value && value.length > 4000) {
                return 'Email Content maximum html characters are 4000'
            } else if (!value || value.indexOf('${otpCode}') < 0) {
                return 'Email Content is missing ${otpCode}'
            }
            return true;
        },
        validateSmsContent(value) {
            if (!this.ic.mfaEnabled || !this.ic.mfaSms) {
                return true;
            }
            if (value && value.length > 255) {
                return 'SMS Content maximum html characters are 255'
            } else if (!value || value.indexOf('${otpCode}') < 0) {
                return 'SMS Content is missing ${otpCode}'
            }
            return true;
        }
    }
};
</script>
<style lang="scss">
.dict-wrapper {
    margin-top: 0.5rem;
    border-width: 1px;
    padding: 0.5rem 0.25rem 0.5rem 0.5rem;
    border-radius: 0.25rem;
    background-color: rgb(249,250,251);
    border-color: rgb(229, 231, 235);
    border-style: solid;
    width: 100%;
    display: block;
}

.dict-title {
    font-size: 0.75rem;
    line-height: 1rem;
    color: rgb(107,114,128);
}

.dict-body {
    display: flex;
    margin-top: 0.25rem;
}

.dict-item {
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-width: 1px;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-right: 0.5rem;
    background-color: rgb(243,244,246);
    color: rgb(107,114,128);
    border-color: rgb(229, 231, 235);
    border-style: solid;
    margin-left: 0.5rem;
}

.dict-item:first-child {
    margin-left: 0;
}

.dict-item:hover {
    border-color: rgb(107,114,128);

}
.dict-item:active {
    border-color: rgb(229, 231, 235);
    background-color: rgb(229, 231, 235);
}
</style>
